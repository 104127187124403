
<style lang="scss">
@import "./sigin.page";
</style>
<style>
@import "./css/util.css";
.login-page-modal {
  padding: 15px 30px;
  background: rgb(246, 245, 242);
  /* background-color: #fff; */
  border-radius: 10px;
  width: 500px;
  max-height: 98vh;
  overflow: hidden;
  @media only screen and (max-width: 740px) {
    width: 100%;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* height: 100vh; */
    /* height: 100dvh; */
    border-radius: unset;
    /* overflow: scroll; */
  }
}
.policy-page-modal {
  padding: 15px 20px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  height: 90%;
  overflow: scroll;
  @media only screen and (max-width: 740px) {
    width: 100%;
    height: 80%;
    padding: 40px 10px;
    /* padding: 10px 15px 110px 15px; */
    border-radius: unset;
    /* height: 100vh; */
    /* height: 100dvh; */
    /* height: 100svh; */
  }
}
.save-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
  gap: 1vw;
  cursor: pointer;
}
.save-login-checked {
  font-size: 20px;
  color: #ff7110;
}
.save-login-unchecked {
  font-size: 20px;
  color: #666666;
}
</style>
url('./img/NewAdminBackground.jpg')
<template>
  <div class="limiter" style="padding-top: 0">
    <div
      class="container-login100"
      :style="{
        backgroundImage:
          this.whitelabel.data.imgs.img_background !== null
            ? 'url(' + this.whitelabel.data.imgs.img_background + ')'
            : 'url(' + require('./img/NewAdminBackground.jpg') + ')',
      }"
    >
      <!-- :style="{ backgroundImage:'url(' + require('./img/NewAdminBackground.jpg') + ')' }" -->

      <div v-if="!visiblePolicy" class="login-page-modal">
        <form
          v-if="form_create"
          class="login100-form validate-form"
          @submit.prevent="efetuarLogin"
        >
          <span class="login100-form-title" style="">
            <img
              :class="
                this.whitelabel.data.imgs.img_logo !== null
                  ? 'img-logotipo-whitelabel'
                  : 'img-logotipo'
              "
              :src="
                this.whitelabel.data.imgs.img_logo != null
                  ? this.whitelabel.data.imgs.img_logo
                  : require('./img/logotipo.png')
              "
              :alt="
                this.whitelabel.data.imgs.img_logo != null
                 ? 'Logo' : 'Logo Clube da Cotação'
              "
            />

            <!-- <img class="img-logotipo" src="./img/logotipo.png" alt=""> -->
          </span>

          <div
            style="margin-bottom: 10px"
            class="wrap-input100 validate-input"
            data-validate="Usuário é obrigatório"
            v-bind:class="[login_required ? 'alert-validate' : '']"
          >
            <span class="label-input100">Usuário</span>
            <input
              class="input100"
              type="text"
              name="username"
              placeholder="Nome de usuário*"
              v-model="login"
              @keyup="clear_message()"
            />
            <i class="fa fa-user focus-input100"></i>
          </div>

          <div
            class="wrap-input100 validate-input"
            data-validate="Senha é obrigatório"
            v-bind:class="[password_required ? 'alert-validate' : '']"
          >
            <span class="label-input100">Senha</span>
            <input
              class="input100"
              type="password"
              name="pass"
              placeholder="Sua Senha*"
              v-model="password"
              @keyup="clear_message()"
            />
            <i class="fas fa-lock focus-input100"></i>
          </div>

          <div class="text-right p-t-2" style="margin-bottom: 10px">
            <a href="#" @click="change_form"> Esqueceu a senha? </a>
          </div>

          <div
            v-if="error_msg"
            class="text-center text-alert p-t-2 p-b-2 fs-16"
          >
            <i class="fas fa-exclamation-triangle pr-2"></i
            ><span class="text-right p-t-2 p-b-5">{{ error_msg }}</span>
          </div>

          <div class="container-login100-form-btn">
            <div class="wrap-login100-form-btn">
              <div class="login100-form-bgbtn"></div>
              <button class="login100-form-btn" :disabled="loading">
                <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                <span v-if="!loading">Login</span>
              </button>
            </div>
          </div>

          <div class="save-login-container" v-on:click="saveLogin = !saveLogin">
            <span
              class="material-icons-outlined save-login-checked"
              v-if="saveLogin"
              >check_box</span
            >
            <span class="material-icons-outlined save-login-unchecked" v-else
              >check_box_outline_blank</span
            >
            <div class="txt1">Salvar dados de acesso</div>
          </div>

          <!-- <div
            v-if="showDiv"
            class="txt1 text-center"
            style="margin-top:5px; margin-bottom: 5px"
          >
            <span>
              Ainda não tem conta? Faça o cadastro e aproveite o Club
            </span>
          </div> -->

          <!-- <button
            v-if="showDiv"
            type="button"
            v-on:click="CreateAccount"
            :disabled="loading"
            class="container-create-account"
          >
            Sou Comprador e quero me Cadastrar
          </button> -->

          <div
            v-if="showDiv"
            class="txt1 text-center"
            style="margin-top: 5px; margin-bottom: 1vh"
          >
            <span> Nossas redes sociais </span>
          </div>

          <div v-if="showDiv" class="flex-c-m" style="margin-bottom: 10px">
            <a
              href="https://www.facebook.com/clubdacotacao/"
              target="_blank"
              class="login100-social-item bg1"
            >
              <img src="@/assets/Facebook_black.svg" alt="" />
            </a>

            <a
              href="https://www.instagram.com/clubdacotacao/?hl=pt"
              target="_blank"
              class="login100-social-item bg2"
            >
              <img src="@/assets/Instagram_black.svg" alt="" />
            </a>

            <a
              href="https://www.youtube.com/channel/UCiP8ACo7VDoIzlUwVC5m5Xw"
              target="_blank"
              class="login100-social-item bg3"
            >
              <img src="@/assets/Youtube_black.svg" alt="" />
            </a>
          </div>
          <!-- <p @click="viewPrivacyPolicy" class="privacyPolicy">
            Política de privacidade
          </p> -->
          <p
            @click="viewPrivacyPolicy"
            style="text-align: center; cursor: pointer"
          >
            Política de privacidade
          </p>
        </form>

        <form
          v-if="!form_create"
          class="login100-form validate-form"
          @submit.prevent="send_password"
        >
          <span class="login100-form-title p-b-49">
            <!-- <img class="img-logotipo" src="./img/logotipo.png" alt="" /> -->
            <img
              :class="
                this.whitelabel.data.imgs.img_logo !== null
                  ? 'img-logotipo-whitelabel'
                  : 'img-logotipo'
              "
              :src="
                this.whitelabel.data.imgs.img_logo != null
                  ? this.whitelabel.data.imgs.img_logo
                  : require('./img/logotipo.png')
              "
              :alt="
                this.whitelabel.data.imgs.img_logo != null
                  ? 'Logo'
                  : 'Logo Clube da Cotação'
              "
            />
          </span>

          <div
            class="wrap-input100 validate-input m-b-23"
            data-validate="Email ou usuário é obrigatório"
            v-bind:class="[login_required ? 'alert-validate' : '']"
          >
            <span class="label-input100">Email ou Login</span>
            <input
              class="input100"
              type="text"
              name="mail"
              placeholder="Email usuário*"
              v-model="mail"
              @keyup="clear_message()"
            />
            <i class="fa fa-user focus-input100"></i>
          </div>

          <div class="text-right p-t-8 p-b-31">
            <a href="#" @click="change_form"> Voltar </a>
          </div>
          <div
            v-if="msg_result"
            class="text-center p-t-5 p-b-5 fs-16"
            v-bind:class="{
              'text-alert': error_msg,
              'text-sucess': success_msg,
            }"
          >
            <i v-if="error_msg" class="fas fa-exclamation-triangle pr-2"></i>
            <i v-if="success_msg" class="fas fa-check-square pr-2"></i>
            <span class="text-right p-t-5 p-b-5">{{
              error_msg ? error_msg : success_msg
            }}</span>
          </div>

          <div class="container-login100-form-btn">
            <div class="wrap-login100-form-btn">
              <div class="login100-form-bgbtn"></div>
              <button class="login100-form-btn" :disabled="loading">
                <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                <span v-if="!loading">Enviar</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div v-if="visiblePolicy" class="policy-page-modal">
        <div class="closeContainerPolicy">
          <span
            style="cursor: pointer"
            v-on:click="viewPrivacyPolicy"
            class="material-icons modal-close-icon"
            >cancel</span
          >
        </div>
        <div class="containerPolicy">
          <h5>Política de Privacidade</h5>
          <p>
            O Club da Cotação desenvolve aplicativos como serviços comerciais.
            Esta página é usada para informar os visitantes sobre nossas
            políticas com a coleta, uso e divulgação de Informações Pessoais,
            caso alguém decida usar nosso Serviço. Se você optar por usar nosso
            Serviço, concorda com a coleta e uso de informações em relação a
            esta política. As Informações Pessoais que coletamos são usadas para
            fornecer e melhorar o Serviço. Não usaremos nem compartilharemos
            suas informações com ninguém, exceto conforme descrito nesta
            Política de Privacidade. Os termos usados nesta Política de
            Privacidade têm os mesmos significados que em nossos Termos e
            Condições, a menos que definido de outra forma nesta Política de
            Privacidade.
          </p>
          <h5>Coleta e Uso de Informações</h5>
          <p>
            Para uma melhor experiência, ao usar nosso Serviço, podemos
            solicitar que você nos forneça certas informações pessoalmente
            identificáveis, incluindo, mas não se limitando a login, senha. As
            informações que solicitamos serão retidas por nós e usadas conforme
            descrito nesta política de privacidade. O aplicativo usa serviços de
            terceiros que podem coletar informações usadas para identificá-lo.
          </p>
          <h5>Dados de Registro</h5>
          <p>
            Queremos esclarecer que não coletamos dados de registro durante o
            uso do nosso Serviço. Não rastreamos informações como endereço do
            Protocolo de Internet ("IP") do seu dispositivo, nome do
            dispositivo, versão do sistema operacional, ou qualquer outra
            informação de registro.
          </p>
          <h5>Cookies</h5>
          <p>
            Cookies são arquivos com uma pequena quantidade de dados que são
            comumente usados como identificadores únicos anônimos. Eles são
            enviados para o seu navegador a partir dos sites que você visita e
            são armazenados na memória interna do seu dispositivo. Este Serviço
            não usa explicitamente esses "cookies". No entanto, o aplicativo
            pode usar códigos e bibliotecas de terceiros que usam "cookies" para
            coletar informações e melhorar seus serviços. Você tem a opção de
            aceitar ou recusar esses cookies e saber quando um cookie está sendo
            enviado para o seu dispositivo. Se você optar por recusar nossos
            cookies, pode ser que não consiga usar algumas partes deste Serviço.
          </p>
          <h5>Provedores de Serviços</h5>
          <p>
            Podemos empregar empresas e indivíduos terceirizados devido aos
            seguintes motivos:
          </p>
          <ul>
            <li>Para facilitar nosso Serviço;</li>
            <li>Para fornecer o Serviço em nosso nome;</li>
            <li>Para realizar serviços relacionados ao Serviço;</li>
            <li>Para nos ajudar a analisar como nosso Serviço é usado.</li>
          </ul>
          <p>
            Queremos informar aos usuários deste Serviço que essas terceiras
            partes não têm acesso às suas Informações Pessoais. Apenas realizam
            as tarefas atribuídas a eles em nosso nome.
          </p>
          <h5>Segurança</h5>
          <p>
            Valorizamos sua confiança em nos fornecer suas Informações Pessoais
            e, portanto, estamos nos esforçando para usar meios comercialmente
            aceitáveis para protegê-las. Mas lembre-se de que nenhum método de
            transmissão pela internet ou método de armazenamento eletrônico é
            100% seguro e confiável, e não podemos garantir sua segurança
            absoluta.
          </p>
          <h5>Privacidade de Crianças</h5>
          <p>
            Estes Serviços não se destinam a menores de 13 anos. Não coletamos
            intencionalmente informações pessoalmente identificáveis de crianças
            menores de 13 anos. No caso de descobrirmos que uma criança com
            menos de 13 anos nos forneceu informações pessoais, excluiremos
            imediatamente essas informações de nossos servidores. Se você é pai
            ou responsável e tem conhecimento de que seu filho nos forneceu
            informações pessoais, entre em contato conosco para que possamos
            tomar as medidas necessárias. Alterações a Esta Política de
            Privacidade Podemos atualizar nossa Política de Privacidade de
            tempos em tempos. Portanto, recomendamos que você revise esta página
            periodicamente para verificar quaisquer alterações. Notificaremos
            você sobre quaisquer alterações publicando a nova Política de
            Privacidade nesta página.
          </p>
          <h5>Entre em Contato</h5>
          <p>
            Se você tiver alguma dúvida ou sugestão sobre nossa Política de
            Privacidade, não hesite em entrar em contato conosco em
            <a href="mailto:suporte@clubdacotacao.com.br"
              >suporte@clubdacotacao.com.br</a
            >.
          </p>
        </div>
      </div>
      <!-- <p @click="viewPrivacyPolicy" class="privacyPolicy">
        Política de privacidade
      </p> -->
    </div>
  </div>
</template>

<script>
import * as CONSTANTS from "@/constants/constants";
import StorageService from "@/services/TokenService";
import AuthenticationService from "@/services/v3/auth.service";
import AuthService from "@/services/v1/AuthService.js";
import CredentialService from "@/services/v2/credentials.service";
import { mapState } from "vuex";
export default {
  data() {
    return {
      login: "",
      password: "",
      mail: "",
      loading: false,
      error_msg: "",
      success_msg: null,
      msg_result: null,
      storage_svc: new StorageService(),
      svc: new AuthenticationService(),
      svcV1: new AuthService(),
      cred_svc: new CredentialService(),
      login_required: false,
      password_required: false,
      form_create: true,
      saveLogin: false,
      showSocialMediaIcons: false,
      visiblePolicy: false,
    };
  },
  methods: {
    efetuarLogin() {
      this.verifyInput();
      if (/\S/.test(this.login) && /\S/.test(this.password)) {
        this.success_msg = null;
        this.error_msg = null;
        this.loading = true;

        this.svc
          .authenticate({
            usu_login: this.login,
            senha: this.password,
          })
          .then(({ data }) => {
            this.loading = false;
            if (this.saveLogin)
              localStorage.setItem(
                "LoginInfo",
                JSON.stringify({ user: this.login, password: this.password })
              );
            this.redirect(data);
          })
          .catch((error) => {
            this.loading = false;
            if (error.response) {
              this.error_msg = error.response.data.errors[0];
            }
            throw error;
          });
      }
    },
    send_password() {
      this.loading = true;
      this.success_msg = null;
      this.error_msg = null;
      this.msg_result = null;
      this.cred_svc
        .send_password(this.mail)
        .then(() => {
          this.loading = false;
          this.msg_result = true;
          this.success_msg =
            "Senha enviada com sucesso! Verifique também na sua caixa de spam";
        })
        .catch((e) => {
          this.msg_result = true;
          this.error_msg =
            "Não conseguimos processar sua requisição, verifique novamente sua credencial informada!";
          this.loading = false;
          ErrorHandlerService.handle(e, this.$store);
        });
    },
    CreateAccount() {
      window.open("https://cadastro.clubdacotacao.com.br/", "_blank");
    },
    verifyInput() {
      this.login_required = this.login ? false : true;
      this.password_required = this.password ? false : true;
    },
    redirectSuplier() {
      this.svcV1
        .authenticate({
          usu_login: this.login,
          senha: this.password,
        })
        .then((resp) => {
          window.location.href =
            CONSTANTS.API_REDIRECT_PROVIDER +
            "/login?external=1&token=" +
            resp.data.token;
        });
    },
    redirectClient() {
      let whitelabelStringfied = JSON.stringify(this.whitelabel.data);

      this.svcV1
        .authenticate({
          usu_login: this.login,
          senha: this.password,
        })
        .then((resp) => {
          window.location.href =
            CONSTANTS.API_REDIRECT_CLIENT +
            "?token=" +
            resp.data.token +
            "&theme=" +
            encodeURIComponent(whitelabelStringfied);
        });
    },
    redirect(data) {
      if (data.cli_id) {
        this.redirectClient();
      } else if (data.for_id) {
        this.redirectSuplier();
      } else {
        this.storage_svc.put("revenda", data.revenda_id);
        this.storage_svc.put("perfilId", data.perfil_id);
        this.storage_svc.put("token", data.token);
        this.storage_svc.put("user_name", data.name);
        this.storage_svc.put("perms", JSON.stringify(data.permissions));

        this.$router.push("/cliente/monitores/cotacoes");
      }
    },
    clear_message() {
      this.msg_result = false;
      this.error_msg = null;
    },
    change_form() {
      this.clear_message();
      this.form_create = !this.form_create;
      this.password = "";
      this.mail = "";
      this.login = "";
    },
    viewPrivacyPolicy() {
      this.visiblePolicy = !this.visiblePolicy;
    },
  },
  mounted() {
    document.body.style.zoom = "100%";
    let savedLogin = localStorage.getItem("LoginInfo");
    if (savedLogin) {
      savedLogin = JSON.parse(savedLogin);
      this.login = savedLogin.user;
      this.password = savedLogin.password;
      this.saveLogin = true;
    }
    this.$store.dispatch("user/logout");
  },
  beforeDestroy() {
    // Volta o zoom para 80% ao sair do componente
    document.body.style.zoom = "80%";
  },
  computed: {
    ...mapState(["whitelabel"]),
    showDiv() {
      return (
        window.location.href.includes("admin.clubdacotacao") ||
        window.location.href.includes("localhost")
      );
    },
  },
  created() {
    if (this.$route.query.showPolicy) {
      this.visiblePolicy = true;
    }
  },
  watch: {
    showDiv() {
      this.showSocialMediaIcons = this.showDiv;
    },
  },
};
</script>
